import { Injectable, EventEmitter } from "@angular/core";
import { ToastModel } from "../models/view-models/ToastModel";
import { OrganizationDetailsByOrgURL } from "../models/view-models/organization";

@Injectable({
  providedIn: "root",
})
export class EventEmitterService {
  toastManipulation = new EventEmitter();
  subDomainDataManipulation = new EventEmitter();
  loadNewRoutesAndModules = new EventEmitter();
  newRoutesForApplicationLoaded = new EventEmitter();
  navigationSidebarToggle = new EventEmitter();

  constructor() {}

  showToast(toastData: ToastModel) {
    this.toastManipulation.emit(toastData);
  }

  subDomainDataAltered(dataAltered?: OrganizationDetailsByOrgURL) {
    this.subDomainDataManipulation.emit(dataAltered);
  }

  requestNewRoutesAndModules() {
    this.loadNewRoutesAndModules.emit();
  }

  markNewRoutesForApplicationLoaded() {
    this.newRoutesForApplicationLoaded.emit();
  }

  toggleNavigationSidebar(isSidebarShown: boolean) {
    this.navigationSidebarToggle.emit(isSidebarShown);
  }
}
