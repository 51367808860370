import { Routes } from "@angular/router";
import { PageNotFoundComponent } from "../components/page-not-found/page-not-found.component";
import { ComingSoonComponent } from "../components/coming-soon/coming-soon.component";
import { inject, Injectable } from "@angular/core";
import { OrganizationModule } from "../shared/models/view-models/organization";
import { Modules } from "../helpers/enums/modules.enums";
import { AppDBService } from "../shared/services/db.service";
import { CookieService } from "ngx-cookie-service";
import { SignInRequiredComponent } from "../components/sign-in-required/sign-in-required.component";

@Injectable({
  providedIn: "root",
})
export class RouteConfiguration {
  private dbService: AppDBService = inject(AppDBService);
  private cookieService: CookieService = inject(CookieService);

  /**
   * Processes the routes configured
   * @param configuredOrganizationModules OPTIONAL param: Send list of organization modules, if null, it will pick from local DB
   * @returns Promise<Routes>
   */
  async getConfiguredRoutes(
    configuredOrganizationModules?: OrganizationModule[],
  ): Promise<Routes> {
    const allConfiguredRoutes: Routes = [];

    //-- Filling data from local indexed db if not passed
    if (
      !configuredOrganizationModules ||
      (configuredOrganizationModules &&
        configuredOrganizationModules.length == 0)
    ) {
      configuredOrganizationModules = (await this.dbService.getRecord(
        "OrganizationModule",
      )) as OrganizationModule[];
    }

    allConfiguredRoutes.push({
      path: "",
      loadComponent: () => {
        return import("../components/common/common.component").then((m) => {
          return m.CommonComponent;
        });
      },
      children: this.getConfiguredChildRoutes(configuredOrganizationModules),
    });

    allConfiguredRoutes.push({
      path: "coming-soon",
      component: ComingSoonComponent,
    });

    allConfiguredRoutes.push({
      path: "sign-in-required",
      component: SignInRequiredComponent,
    });

    allConfiguredRoutes.push({
      path: "404",
      component: PageNotFoundComponent,
    });

    allConfiguredRoutes.push({
      path: "**",
      component: PageNotFoundComponent,
    });
    return allConfiguredRoutes;
  }

  /*
    To check if the user is sign in, redundant definition to avoid circular dependency
  */
  isUserSignedIn() {
    const userHasAccessToken = this.cookieService.get("accessToken");
    if (userHasAccessToken) {
      this.cookieService.set("isUserGuest", "false");
      this.cookieService.set("isUserVisitor", "false");
    }
    return userHasAccessToken ? true : false;
  }

  getConfiguredChildRoutes(
    configuredOrganizationModules: OrganizationModule[],
  ): Routes {
    const allConfiguredChildRoutes: Routes = [];
    if (
      configuredOrganizationModules &&
      configuredOrganizationModules.length > 0
    ) {
      //-- Assign default route - Starts
      const defaultModule: OrganizationModule | undefined =
        this.dbService.findDefaultModule(configuredOrganizationModules);

      if (defaultModule) {
        allConfiguredChildRoutes.push({
          path: "",
          pathMatch: "full",
          redirectTo: defaultModule?.ModuleURL,
        });
      } else {
        allConfiguredChildRoutes.push({
          path: "",
          pathMatch: "full",
          loadComponent: () => {
            return import(
              "../components/landing-page/landing-page.component"
            ).then((m) => {
              return m.LandingPageComponent;
            });
          },
        });
      }
      //-- Assign default route - Ends

      //-- Add these routes only if the user is signed in - Starts
      if (this.isUserSignedIn()) {
        allConfiguredChildRoutes.push({
          path: "profile/:visitingUserUUId",
          loadComponent: () => {
            return import("../components/profile/profile.component").then(
              (m) => {
                return m.ProfileComponent;
              },
            );
          },
        });
        allConfiguredChildRoutes.push({
          path: "profile-edit/:visitingUserUUId",
          loadComponent: () => {
            return import(
              "../components/profile-edit/profile-edit.component"
            ).then((m) => {
              return m.ProfileEditComponent;
            });
          },
        });
        //!-- Temp-module Remove this later
        allConfiguredChildRoutes.push({
          path: "temp-wr",
          loadComponent: () => {
            return import("../components/temp-wr/temp-wr.component").then(
              (m) => {
                return m.TempWRComponent;
              },
            );
          },
        });
        //!-- Temp-module Remove this later
        allConfiguredChildRoutes.push({
          path: "temp-wr-detail",
          loadComponent: () => {
            return import(
              "../components/temp-wr-detail/temp-wr-detail.component"
            ).then((m) => {
              return m.TempWRDetailComponent;
            });
          },
        });
      }
      //-- Add these routes only if the user is signed in - Ends

      const flattenedOrganizationModules: OrganizationModule[] =
        this.dbService.flattenOrganizationModules(
          configuredOrganizationModules,
        );

      for (const organizationModule of flattenedOrganizationModules) {
        switch (organizationModule.ModuleCoreURL) {
          case Modules.HOME: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/landing-page/landing-page.component"
                ).then((m) => {
                  return m.LandingPageComponent;
                });
              },
            });
            break;
          }
          case Modules.ORGANIZATION: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/organization/organization.component"
                ).then((m) => {
                  return m.OrganizationComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/new",
              loadComponent: () => {
                return import(
                  "../components/organization-details/organization-details.component"
                ).then((m) => {
                  return m.OrganizationDetailsComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/:organizationUUID",
              loadComponent: () => {
                return import(
                  "../components/organization-details/organization-details.component"
                ).then((m) => {
                  return m.OrganizationDetailsComponent;
                });
              },
            });
            break;
          }
          case Modules.DEPARTMENTS: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/department/department-list/department-list.component"
                ).then((m) => {
                  return m.DepartmentListComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/new",
              loadComponent: () => {
                return import(
                  "../components/department/department-details/department-details.component"
                ).then((m) => {
                  return m.DepartmentDetailsComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/:departmentUUID",
              loadComponent: () => {
                return import(
                  "../components/department/department-details/department-details.component"
                ).then((m) => {
                  return m.DepartmentDetailsComponent;
                });
              },
            });
            break;
          }
          case Modules.MODULES: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import("../components/modules/modules.component").then(
                  (m) => {
                    return m.ModulesComponent;
                  },
                );
              },
            });
            allConfiguredChildRoutes.push({
              path:
                organizationModule.ModuleURL +
                "/:organizationUUID/:organizationModuleUUID",
              loadComponent: () => {
                return import(
                  "../components/modules/edit-modules/edit-modules.component"
                ).then((m) => {
                  return m.EditModulesComponent;
                });
              },
            });
            break;
          }
          case Modules.USERS: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import("../components/user/user.component").then((m) => {
                  return m.UserComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/invites",
              loadComponent: () => {
                return import(
                  "../components/user/invites/invites.component"
                ).then((m) => {
                  return m.InvitesComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/invites/send-invite",
              loadComponent: () => {
                return import(
                  "../components/user/invites/send-invite/send-invite.component"
                ).then((m) => {
                  return m.SendInviteComponent;
                });
              },
            });
            break;
          }
          case Modules.USERROLES: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/user-roles/user-roles.component"
                ).then((m) => {
                  return m.UserRolesComponent;
                });
              },
            });
            break;
          }
          case Modules.MESSAGEBOARD: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/message-board/message-board.component"
                ).then((m) => {
                  return m.MessageBoardComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/new",
              loadComponent: () => {
                return import(
                  "../components/message-board/add-message/add-message.component"
                ).then((m) => {
                  return m.AddMessageComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/:messageUUID",
              loadComponent: () => {
                return import(
                  "../components/message-board/add-message/add-message.component"
                ).then((m) => {
                  return m.AddMessageComponent;
                });
              },
            });
            break;
          }
          case Modules.CATEGORY: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/category/category-list/category-list.component"
                ).then((m) => {
                  return m.CategoryListComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/new",
              loadComponent: () => {
                return import(
                  "../components/category/category-details/category-details.component"
                ).then((m) => {
                  return m.CategoryDetailsComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/:categoryUUID",
              loadComponent: () => {
                return import(
                  "../components/category/category-details/category-details.component"
                ).then((m) => {
                  return m.CategoryDetailsComponent;
                });
              },
            });
            break;
          }
          case Modules.EVENTS:
          case Modules.HISTORY:
          case Modules.STORE:
          case Modules.ORDERS:
          case Modules.REPORTS:
          case Modules.SERVICES:
          case Modules.TERMSOFSERVICES:
          case Modules.PRIVACYPOLICY:
          case Modules.HELP:
          case Modules.SETTINGS:
          case Modules.REQUESTS:
          case Modules.ADMIN:
          case Modules.CHAT:
          case Modules.CUSTOMPAGE:
          case Modules.USERDEFINEDPAGE: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/coming-soon/coming-soon.component"
                ).then((m) => {
                  return m.ComingSoonComponent;
                });
              },
            });
            break;
          }
          default: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/page-not-found/page-not-found.component"
                ).then((m) => {
                  return m.PageNotFoundComponent;
                });
              },
            });
            break;
          }
        }
      }
    }
    allConfiguredChildRoutes.push({
      path: "coming-soon",
      component: ComingSoonComponent,
    });
    //!-- Temp-preview Remove this later
    allConfiguredChildRoutes.push({
      path: "preview",
      loadComponent: () => {
        return import(
          "../components/designer-ui-preview/designer-ui-preview.component"
        ).then((m) => {
          return m.DesignerUiPreviewComponent;
        });
      },
    });

    allConfiguredChildRoutes.push({
      path: "404",
      component: PageNotFoundComponent,
    });

    allConfiguredChildRoutes.push({
      path: "**",
      component: PageNotFoundComponent,
    });
    return allConfiguredChildRoutes;
  }
}
