import { Component } from "@angular/core";
import { RouterLink } from "@angular/router";
import { AnimationOptions, LottieComponent } from "ngx-lottie";

@Component({
  selector: "app-page-not-found",
  standalone: true,
  imports: [RouterLink, LottieComponent],
  templateUrl: "./page-not-found.component.html",
  styleUrl: "./page-not-found.component.scss",
})
export class PageNotFoundComponent {
  loaderOptions: AnimationOptions = {
    path: "../../../assets/images/404Page.json",
  };
}
