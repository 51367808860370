export enum ToastType {
    SUCCESS,
    ERROR,
    INFO,
    WARNING,
    // PERMISSION,
    // NOTIFICATION,
}

export interface ToastModel {
    Title?: string;
    Text?: string;
    ToastType?: ToastType;
    RemovePreviousAlerts?: boolean;
    OnActionCallback?: () => void;
    OnHiddenCallback?: () => void;
    OnShownCallback?: () => void;
    OnTapCallback?: () => void;
}


export interface ToastInstance {
  onAction: { subscribe: (callback: () => void) => void };
  onHidden: { subscribe: (callback: () => void) => void };
  onShown: { subscribe: (callback: () => void) => void };
  onTap: { subscribe: (callback: () => void) => void };
}
