/* eslint-disable @typescript-eslint/no-explicit-any */
export enum ResponseCode {
  WARNING = 0,
  ERROR = 1,
  SUCCESS = 2,
}
export interface ResponseHelper {
  Status?: ResponseCode;
  Message?: string;
  Payload?: any;
  UserMessage?: string;
}
