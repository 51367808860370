import { Component } from "@angular/core";
import { RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { AnimationOptions, LottieComponent } from "ngx-lottie";

@Component({
  selector: "app-coming-soon",
  standalone: true,
  imports: [RouterLink, TranslateModule, LottieComponent],
  templateUrl: "./coming-soon.component.html",
})
export class ComingSoonComponent {
  comingSoonLoader: AnimationOptions = {
    path: "../../../assets/images/comingSoon.json",
  };
  comingSoonLoaderStyle: Partial<CSSStyleDeclaration> = {
    height: "60vh",
  };
}
