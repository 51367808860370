// eslint-disable-next-line @typescript-eslint/no-explicit-any
const config: any = {
  pointing: "dev", // local || dev || prod
  rootURLs: {
    local: "https://myharmonic-local.app/",
    dev: "https://myharmonic-dev.app/",
    prod: "https://platform.gogamers.tech/", // https://platform.gogamers.tech/
  },
  rootAPIURLs: {
    local: "https://myharmonic-local.app/api",
    dev: "https://myharmonic-dev.app/api",
    prod: "https://platform.gogamers.tech/api", // https://platform.gogamers.tech/
  },
  sentryDSN: {
    local:
      "https://d1bbf9f660d2b567c4e66d1e0a18c27f@o4507293093527552.ingest.de.sentry.io/4507430098698320",
    dev: "https://d1bbf9f660d2b567c4e66d1e0a18c27f@o4507293093527552.ingest.de.sentry.io/4507430098698320",
    prod: "https://d1bbf9f660d2b567c4e66d1e0a18c27f@o4507293093527552.ingest.de.sentry.io/4507430098698320",
  },
  branding: {
    local: {
      defaultOrganizationURL: "sge",
      defaultBrandName: "SGE",
      defaultColoredBrandIconDarkURL:
        "https://firebasestorage.googleapis.com/v0/b/go-gamers-tech.appspot.com/o/Icons%2Fgg-tech-global-winner-logo-update.png?alt=media&token=4f53832b-39f4-46fa-a726-6580159f2add",
      defaultWhiteBrandIconURL:
        "https://firebasestorage.googleapis.com/v0/b/go-gamers-tech.appspot.com/o/Icons%2Fgg-tech-global-winner-logo-update.png?alt=media&token=4f53832b-39f4-46fa-a726-6580159f2add",
    },
    dev: {
      defaultOrganizationURL: "sge",
      defaultBrandName: "SGE",
      defaultColoredBrandIconDarkURL:
        "https://firebasestorage.googleapis.com/v0/b/go-gamers-tech.appspot.com/o/Icons%2Fgg-tech-global-winner-logo-update.png?alt=media&token=4f53832b-39f4-46fa-a726-6580159f2add",
      defaultWhiteBrandIconURL:
        "https://firebasestorage.googleapis.com/v0/b/go-gamers-tech.appspot.com/o/Icons%2Fgg-tech-global-winner-logo-update.png?alt=media&token=4f53832b-39f4-46fa-a726-6580159f2add",
    },
    prod: {
      defaultOrganizationURL: "sge",
      defaultBrandName: "SGE",
      defaultColoredBrandIconDarkURL:
        "https://firebasestorage.googleapis.com/v0/b/go-gamers-tech.appspot.com/o/Icons%2Fgg-tech-global-winner-logo-update.png?alt=media&token=4f53832b-39f4-46fa-a726-6580159f2add",
      defaultWhiteBrandIconURL:
        "https://firebasestorage.googleapis.com/v0/b/go-gamers-tech.appspot.com/o/Icons%2Fgg-tech-global-winner-logo-update.png?alt=media&token=4f53832b-39f4-46fa-a726-6580159f2add",
    },
  },
  firebaseContent: {
    local: {
      apiKey: "AIzaSyCbVKBa-ZAMPeLbqYe1vba8RjZ02WR43n8",
      authDomain: "harmonicdevapp.firebaseapp.com",
      projectId: "harmonicdevapp",
      storageBucket: "harmonicdevapp.appspot.com",
      messagingSenderId: "860846154434",
      appId: "1:860846154434:web:b2979ce1f058b158ded15b",
      measurementId: "G-LTYSMW9S2W",
    },
    dev: {
      apiKey: "AIzaSyCbVKBa-ZAMPeLbqYe1vba8RjZ02WR43n8",
      authDomain: "harmonicdevapp.firebaseapp.com",
      projectId: "harmonicdevapp",
      storageBucket: "harmonicdevapp.appspot.com",
      messagingSenderId: "860846154434",
      appId: "1:860846154434:web:b2979ce1f058b158ded15b",
      measurementId: "G-LTYSMW9S2W",
    },
    prod: {
      apiKey: "AIzaSyCbVKBa-ZAMPeLbqYe1vba8RjZ02WR43n8",
      authDomain: "harmonicdevapp.firebaseapp.com",
      projectId: "harmonicdevapp",
      storageBucket: "harmonicdevapp.appspot.com",
      messagingSenderId: "860846154434",
      appId: "1:860846154434:web:b2979ce1f058b158ded15b",
      measurementId: "G-LTYSMW9S2W",
    },
  },
  vapidKey:
    "BA-5JS-EmwISNjNYPAIH5xgqwqhGuiiJT3zYLo7xEYnv88OcsfHd9wzOo59pMbr-BdBfwWvJAWR_3_HWZuvwTl8",
};

export const environment = {
  production:
    config.pointing == "dev" || config.pointing == "prod" ? "true" : "false",
  organizationURL: config.branding[config.pointing].defaultOrganizationURL,
  organizationName: config.branding[config.pointing].defaultBrandName,
  sentryDSNUrl: config.sentryDSN[config.pointing],
  coloredBrandIconDarkURL:
    config.branding[config.pointing].defaultColoredBrandIconDarkURL,
  whiteBrandIconURL: config.branding[config.pointing].defaultWhiteBrandIconURL,
  rootAPIURL: config.rootAPIURLs[config.pointing],
  rootURL: config.rootURLs[config.pointing],
  firebaseConfig: config.firebaseContent[config.pointing],
  vapidKey: config.vapidKey,
};
